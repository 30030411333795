module services {
    export module master {
        export class productTypeService implements interfaces.master.IProductTypeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all Product Types for a dropdown/autocomplete list
            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return <ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel>>
                    this.$resource(this.ENV.DSP_URL + "ProductType/GetDropdownList", {
                        ownerEntityId: '@ownerEntityId'
                    }, {
                            query: {
                                method: 'GET',
                                isArray: true
                            }
                        });
            }
            
            getList(): ng.resource.IResourceClass<interfaces.tariff.IProductTypeDisplay> {
                return this.$resource<interfaces.tariff.IProductTypeDisplay>(this.ENV.DSP_URL + "ProductType/GetList", {
                    entityId: '@entityId',
                    description: '@description',
                    typeCode: '@typeCode',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }
            
            SaveProductTypes(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ProductType/SaveProductTypes", {
                });
            }

            //Makes product inactive.
            removeProductType(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ProductType/RemoveProductType", {
                    prodId: '@prodId',
                });
            }
        }
    }
    angular.module("app").service("productTypeService", services.master.productTypeService);
}